import styled from '@emotion/styled';
import type { Theme } from 'widget/styles/themes';

type Props = {
  'data-test'?: string;
  onClick?: () => void;
};

const Button = styled('button')<Props>(({ theme }: { theme: Theme }) => ({
  ':focus': {
    outline: theme.highlightOutline,
  },
  background: 'none',
  border: 'none',
  padding: 0,
}));

export default Button;
