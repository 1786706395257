import UrlParse from 'url-parse';

const addFit = (url: string, width = 240, height = 240) => {
  const parsed = new UrlParse(url, true);
  const opString = `fit(${width},${height})`;
  if (parsed.query.ops) {
    parsed.set('query', {
      ...parsed.query,
      ops: `${parsed.query.ops},${opString}`,
    });
  } else {
    parsed.set('query', { ...parsed.query, ops: opString });
  }
  return parsed.toString();
};

export default addFit;
