import PlayerContainerPrimitive from './primitives/PlayerContainer';
import PlayerWrapper from './primitives/PlayerWrapper';

type Props = {
  children: Array<JSX.Element> | JSX.Element;
  positionBottom?: boolean;
};

const PlayerContainer = ({ children, positionBottom }: Props) => (
  <PlayerWrapper>
    <PlayerContainerPrimitive positionBottom={positionBottom}>
      {children}
    </PlayerContainerPrimitive>
  </PlayerWrapper>
);

export default PlayerContainer;
