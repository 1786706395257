import fonts from 'styles/fonts';
import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
  hasPlayed?: boolean;
  isPodcast?: boolean;
};

const InfoHeading = styled('h3')<Props>(({ hasPlayed, isPodcast }: Props) => {
  const rightMarginTruncated = isPodcast ? '22rem' : '14rem';

  return {
    fontSize: fonts.size['12'],
    lineHeight: fonts.lineHeight['16'],
    marginRight: '2.5rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [mediaQueryBuilder([
      mediaQueries.max.height['99'],
      mediaQueries.min.width['420'],
    ])]: {
      marginRight: hasPlayed ? rightMarginTruncated : '2.5rem',
    },
  };
});

export default InfoHeading;
