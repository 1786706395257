import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
  positionBottom?: boolean;
};

const PlayerContainer = styled('div')<Props>(({ positionBottom }: Props) => ({
  bottom: positionBottom ? 0 : 'auto',
  position: 'fixed',
  top: positionBottom ? 'auto' : '9rem',
  width: '100%',
  [mediaQueryBuilder(mediaQueries.max.height['169'])]: {
    bottom: 0,
    top: 'auto',
  },
  [mediaQueryBuilder(mediaQueries.max.height['99'])]: {
    bottom: 'auto',
  },
}));

export default PlayerContainer;
