import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const MessageContainer = styled('div')<Props>({
  [mediaQueryBuilder('(min-height: 169px)')]: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
});

export default MessageContainer;
