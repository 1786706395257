import safeStringify from 'utils/safeStringify';
import {
  EVENT_BASE,
  EVENT_ON_DEMAND,
  METHOD_BASE,
  METHOD_ON_DEMAND,
  METHOD_RESTRICTED,
} from 'widget/components/withPlayerJS/constants';
import {
  EventType,
  Listeners,
  SupportedEventsType,
  SupportedMethodsType,
} from 'widget/components/withPlayerJS/types';
import { getErrorMessageTranslator } from 'widget/Errors/PlaybackError/helpers/getErrorMessageTranslator';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import {
  PlayerErrorState,
  PlayerWarningState,
} from 'modules/Player/constants/playerState';

export function createEmitMessageFnc(
  context: string,
  version: string,
  windowRef?: Window,
) {
  return function emitMessage(
    event: string,
    value?: any,
    listener?: string,
  ): void {
    // eslint-disable-next-line no-unused-expressions
    windowRef?.parent.postMessage(
      safeStringify({
        context,
        event,
        listener,
        value,
        version,
      }),
      '*',
    );
  };
}

export function createHandleErrorMsgFnc(
  translate: IGetTranslateFunctionResponse,
) {
  const getErrorMsg = getErrorMessageTranslator(translate);
  return function handleError({
    code,
    error,
    method,
    msg: message,
  }: {
    code: -1 | 1 | 2;
    error?: PlayerErrorState | PlayerWarningState;
    method?: string;
    msg?: string;
  }) {
    const playerjsCodes = {
      // JW Player errors are mapped to Player.js code -1
      [-1]: getErrorMsg(error),
      1: translate('Playback not supported by device or browser.'), // not currently used in withPlayerJS
      2: translate('Method {method} not supported.', { method }),
    };
    const msg = message || playerjsCodes[code];
    return { code, msg };
  };
}

export function getSupportedEventsAndMethods({
  isLiveStream = false,
  isUnrestricted = false,
}): [SupportedEventsType, SupportedMethodsType] {
  return [
    {
      ...EVENT_BASE,
      ...(isLiveStream ? {} : EVENT_ON_DEMAND),
    },
    {
      ...METHOD_BASE,
      ...(isLiveStream ? {} : METHOD_ON_DEMAND),
      ...(isUnrestricted ? METHOD_RESTRICTED : {}),
    },
  ];
}

export function getInitialEventListeners(events: EventType) {
  const eventList = Object.values(events);
  const initialEventListeners = eventList.reduce(
    (obj, el) => ({ ...obj, [el]: [] }),
    {} as Listeners,
  );
  return initialEventListeners;
}
