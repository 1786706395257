import RoundedLink from 'widget/components/RoundedLink';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const IHeartLink = styled(RoundedLink)<Props>({
  minWidth: '11rem',
  padding: '0.8rem',
});

export default IHeartLink;
