import CloseButton from 'widget/components/CloseButton';
import styled from '@emotion/styled';
import { Theme } from 'widget/styles/themes';

type Props = {
  'data-test'?: string;
};

const CloseModalButton = styled(CloseButton)<Props>(
  ({ theme }: { theme: Theme }) => ({
    '& svg': {
      fill: 'currentColor',
    },
    color: theme.fontPrimary,
    float: 'right',
  }),
);

export default CloseModalButton;
