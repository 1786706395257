import CloseModalButton from 'widget/components/Modal/primitives/CloseModalButton';
import Wrapper from 'widget/components/Modal/primitives/Wrapper';
import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  onClose: () => void;
};

export default function Modal({ children, onClose }: Props) {
  return (
    <Wrapper data-test="modal-wrapper">
      <CloseModalButton onClick={onClose} />
      {children}
    </Wrapper>
  );
}
