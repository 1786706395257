import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const ContentContainer = styled('div')<Props>({
  display: 'flex',
  flexDirection: 'column',
  [mediaQueryBuilder(mediaQueries.max.height['99'])]: {
    height: 'calc(100vh - 2px)',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: 'calc(100vw - 2px)',
  },
});

export default ContentContainer;
