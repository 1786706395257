import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  supportMobileView?: boolean;
  notYetInteractedWith?: boolean;
  'data-test': string;
};

export const PLAY_BUTTON_BREAKPOINT_SMALL_ZINDEX = 200;

const PlayButtonWrapper = styled('div')<Props>(
  ({ supportMobileView = false, notYetInteractedWith = true }) => ({
    left: supportMobileView && notYetInteractedWith ? '50%' : 'auto',
    margin: supportMobileView && notYetInteractedWith ? 0 : ' 0 0.5rem 0 0',
    objectFit: 'cover',
    position: supportMobileView && notYetInteractedWith ? 'fixed' : 'relative',
    top: supportMobileView && notYetInteractedWith ? '50%' : 'auto',
    transform:
      supportMobileView && notYetInteractedWith ?
        'translate(-50%, -50%)'
      : 'none',
    zIndex: 10,
    [mediaQueryBuilder(
      mediaQueries.max.height['399'],
      mediaQueries.max.width['199'],
    )]: {
      left: 'auto',
      margin: 0,
      position: 'relative',
      transform: 'none',
    },
    // this is here to push over the player controls with small height and long widths.
    [mediaQueryBuilder([
      mediaQueries.max.height['99'],
      mediaQueries.min.width['399'],
    ])]: {
      marginRight: '11rem',
    },
  }),
);

export default PlayButtonWrapper;
