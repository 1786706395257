import ControlButton from 'widget/components/ControlButton';
import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
  onClick: () => void;
  sizeToHide: string;
};

const StyledShareButton = styled(ControlButton)<Props>(({ sizeToHide }) => ({
  paddingTop: '0.3rem',
  [mediaQueryBuilder(`${sizeToHide}`)]: {
    display: 'none',
  },
  [mediaQueryBuilder(
    mediaQueries.max.height['99'],
    mediaQueries.max.width['420'],
  )]: {
    display: 'none',
  },
}));

export default StyledShareButton;
