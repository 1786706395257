import PlayerState, {
  PlayerErrorState,
  PlayerWarningState,
} from 'modules/Player/constants/playerState';
import { Events } from 'widget/components/withPlayerJS/types';

export const context = 'player.js';
export const version = '0.0.11';
export const src = 'iheart.com';

export const nullEvent = null;

export const METHOD_BASE = {
  ADD_EVENT_LISTENER: 'addEventListener',
  GET_MUTED: 'getMuted',
  GET_PAUSED: 'getPaused',
  GET_VOLUME: 'getVolume',
  MUTE: 'mute',
  PAUSE: 'pause',
  PLAY: 'play',
  REMOVE_EVENT_LISTENER: 'removeEventListener',
  SET_VOLUME: 'setVolume',
  UNMUTE: 'unmute',
} as const;
export const METHOD_ON_DEMAND = {
  GET_CURRENT_TIME: 'getCurrentTime',
  GET_DURATION: 'getDuration',
  GET_LOOP: 'getLoop',
  SET_LOOP: 'setLoop',
} as const;
export const METHOD_RESTRICTED = {
  SET_CURRENT_TIME: 'setCurrentTime',
} as const;

export const EVENT_BASE = {
  ENDED: 'ended',
  ERROR: 'error',
  METADATA: 'metadata',
  PAUSE: 'pause',
  PLAY: 'play',
  PROGRESS: 'progress',
  READY: 'ready',
} as const;
export const EVENT_ON_DEMAND = {
  TIME_UPDATE: 'timeupdate',
} as const;
export const EVENT_METHOD_CB = {
  GET_DURATION: METHOD_ON_DEMAND.GET_DURATION,
} as const;

export const playerStateMap: { [key: string]: Events | null } = {
  [PlayerState.Buffering]: EVENT_BASE.PROGRESS,
  [PlayerState.Complete]: EVENT_BASE.ENDED,
  [PlayerState.Idle]: null,
  [PlayerState.Loading]: EVENT_BASE.PROGRESS,
  [PlayerState.Paused]: EVENT_BASE.PAUSE,
  [PlayerState.Playing]: EVENT_BASE.PLAY,
  [PlayerErrorState.AccessDenied]: EVENT_BASE.ERROR,
  [PlayerWarningState.AutoplayBlocked]: EVENT_BASE.ERROR,
  [PlayerErrorState.InvalidMedia]: EVENT_BASE.ERROR,
  [PlayerErrorState.MediaPlaybackError]: EVENT_BASE.ERROR,
  [PlayerWarningState.PlayAttemptFailed]: EVENT_BASE.ERROR,
  [PlayerErrorState.PlayerError]: EVENT_BASE.ERROR,
  [PlayerErrorState.SetupError]: EVENT_BASE.ERROR,
  [PlayerErrorState.UnknownError]: EVENT_BASE.ERROR,
  [PlayerWarningState.Warning]: EVENT_BASE.ERROR,
};

export const defaultMethodTypes = {
  isLiveStream: false,
  isUnrestricted: false,
};
export const defaultMethod = () => {};
