import Button from 'widget/components/Button';
import CloseIcon from 'styles/icons/CloseIcon';

type Props = {
  className?: string;
  onClick: () => void;
};

export default function CloseButton({ onClick, className }: Props) {
  return (
    <Button
      aria-label="Close"
      className={className}
      data-test="close-button"
      onClick={onClick}
      type="button"
    >
      <CloseIcon height="24" width="24" />
    </Button>
  );
}
