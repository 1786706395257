import Button from 'widget/components/Button';
import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test': string;
};

const LargeButton = styled(Button)<Props>({
  '& > svg': {
    transform: 'scale(1.06)',
  },
  display: 'inline-block',
  height: '8.2rem',
  position: 'relative',
  width: '8.2rem',
  [mediaQueryBuilder(
    mediaQueries.max.height['399'],
    mediaQueries.max.width['400'],
  )]: {
    height: '6rem',
    width: '6rem',
  },
  [mediaQueryBuilder(
    mediaQueries.max.height['199'],
    mediaQueries.max.width['299'],
  )]: {
    height: '5rem',
    width: '5rem',
  },
  [mediaQueryBuilder(
    mediaQueries.max.height['119'],
    mediaQueries.max.width['119'],
  )]: {
    bottom: 0,
    height: '4rem',
    width: '4rem',
  },
  [mediaQueryBuilder(mediaQueries.max.height['99'])]: {
    top: 2,
  },
});

export default LargeButton;
