import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const RightButtonGroup = styled('div')<Props>({
  alignItems: 'flex-end',
  display: 'flex',
  whiteSpace: 'nowrap',
});

export default RightButtonGroup;
