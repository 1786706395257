import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const Header = styled('div')<Props>({
  boxSizing: 'border-box',
  minHeight: '6.5rem',
  padding: '1.2rem',
  [mediaQueryBuilder(mediaQueries.max.width['299'])]: {
    padding: '1rem',
  },
  [mediaQueryBuilder(mediaQueries.max.height['119'])]: {
    padding: '1rem',
  },
});

export default Header;
