import fonts from 'styles/fonts';
import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const ErrorDescription = styled('h1')<Props>({
  fontSize: fonts.size['20'],
  fontWeight: 'bold',
  lineHeight: fonts.lineHeight['26'],
  marginBottom: '2rem',
  [mediaQueryBuilder(mediaQueries.max.height['169'])]: {
    fontSize: fonts.size['16'],
    lineHeight: fonts.lineHeight['20'],
    marginBottom: '0.5rem',
  },
});

export default ErrorDescription;
