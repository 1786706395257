import fonts from 'styles/fonts';
import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import { Theme } from 'widget/styles/themes';

type Props = {
  'data-test'?: string;
};

const Body = styled('div')<Props>(({ theme }: { theme: Theme }) => ({
  color: theme.fontTertiary,
  fontSize: fonts.size['16'],
  lineHeight: fonts.size['20'],
  [mediaQueryBuilder(mediaQueries.max.height['149'])]: {
    fontSize: fonts.size['14'],
  },
}));

export default Body;
