export default function PlayIcon(props: {
  [a: string]: string | number | boolean;
}) {
  return (
    <svg
      data-test="play-icon"
      fill="currentColor"
      height="22"
      viewBox="0 0 19 22"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M.94 1.859V20.14a1.761 1.761 0 0 0 2.718 1.483l14.365-9.142c1.094-.688 1.094-2.276 0-2.982L3.658.376A1.761 1.761 0 0 0 .94 1.86z" />
    </svg>
  );
}
