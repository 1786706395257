import Thumbnail from 'widget/components/BigThumbnail/primitives/Thumbail';

type Props = {
  alt?: string;
  supportMobileView?: boolean;
  src: string;
};

const BigThumbnail = ({ alt = '', supportMobileView = false, src }: Props) => (
  <Thumbnail
    alt={alt}
    data-test="image-thumbnail"
    src={src}
    supportMobileView={supportMobileView}
  />
);

export default BigThumbnail;
