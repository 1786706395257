import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import { Theme } from 'widget/styles/themes';

type Props = {
  'data-test'?: string;
};

const Thumb = styled('img')<Props>(({ theme }: { theme: Theme }) => ({
  background: theme.liveThumb,
  borderRadius: '0.6rem',
  boxShadow: theme.thumbBoxShadow,
  display: 'block',
  height: '11.6rem',
  margin: '1rem auto',
  width: '11.6rem',
  [mediaQueryBuilder(mediaQueries.max.width['299'])]: {
    display: 'none',
  },
}));

export default Thumb;
