import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const InfoHeading = styled('div')<Props>({
  display: 'none',
  float: 'left',
  marginRight: '0.5rem',
  [mediaQueryBuilder(mediaQueries.max.height['149'])]: {
    display: 'block',
  },
});

export default InfoHeading;
