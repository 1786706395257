import styled from '@emotion/styled';
import { Theme } from 'widget/styles/themes';

type Props = {
  'data-test'?: string;
};

const Wrapper = styled('div')<Props>(({ theme }: { theme: Theme }) => ({
  background: theme.modalBackground,
  bottom: 0,
  left: 0,
  padding: '1.4rem',
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 9999,
}));

export default Wrapper;
