export default function StopIcon(props: {
  [a: string]: string | number | boolean;
}) {
  return (
    <svg
      data-test="stop-icon"
      fill="currentColor"
      height="26"
      viewBox="396 908 100 100"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M489.5,1004.9h-87.1c-1.9,0-3.4-1.5-3.4-3.4v-87.1c0-1.9,1.5-3.4,3.4-3.4h87.1c1.9,0,3.4,1.5,3.4,3.4v87.1 C492.9,1003.4,491.4,1004.9,489.5,1004.9z" />
    </svg>
  );
}
