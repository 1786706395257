import ShareIcon from 'widget/components/ShareButton/primitives/ShareIcon';
import StyledShareButton from 'widget/components/ShareButton/primitives/StyledShareButton';

type Props = {
  onClick: () => void;
  sizeToHide: string;
};

export default function ShareButton({ onClick, sizeToHide }: Props) {
  return (
    <StyledShareButton
      aria-label="Share"
      data-test="share-button"
      onClick={onClick}
      sizeToHide={sizeToHide}
      type="button"
    >
      <ShareIcon />
    </StyledShareButton>
  );
}
