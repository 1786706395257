import { IGetTranslateFunctionResponse } from 'redux-i18n';
import {
  PlayerErrorState,
  PlayerWarningState,
} from 'modules/Player/constants/playerState';

const bypassTranslate = (x: string) => x;

export type GetPlaybackErrorMessage = (
  error?: PlayerErrorState | PlayerWarningState,
) => string;

type GetErrorMessageTranslator = (
  translate?: IGetTranslateFunctionResponse | typeof bypassTranslate,
) => GetPlaybackErrorMessage;

export const getErrorMessageTranslator: GetErrorMessageTranslator = (
  translate = bypassTranslate,
) => {
  const getPlaybackErrorMessage: GetPlaybackErrorMessage = error => {
    const defaultMsg = translate('Oops! We had problems playing this stream.');
    const errorMsgMap: {
      [key in PlayerErrorState | PlayerWarningState]?: string;
    } = {
      [PlayerErrorState.AccessDenied]: translate(
        'This station is not currently available in your country.',
      ),
      [PlayerWarningState.AutoplayBlocked]: translate(
        `DOMException: play() failed because the user didn't interact with the document first.`,
      ),
      [PlayerErrorState.InvalidMedia]: translate(
        `There's an error with this content.`,
      ),
      [PlayerErrorState.NetworkError]: translate(
        `There was an issue loading your music, please check your connection and try again.`,
      ),
      [PlayerErrorState.UnsupportedCountry]: translate(
        `This content is not supported in your country`,
      ),
    };
    return errorMsgMap[error as PlayerErrorState] || defaultMsg;
  };
  return getPlaybackErrorMessage;
};
