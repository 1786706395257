import LargeButton from 'widget/components/PlayButton/primitives/LargeButton';
import LoadingSpinner, { Style } from 'components/LoadingSpinner';
import PauseIcon from 'widget/components/PlayButton/primitives/PauseIcon';
import PlayIcon from 'widget/components/PlayButton/primitives/PlayIcon';
import StopIcon from 'widget/components/PlayButton/primitives/StopIcon';
import { Theme } from 'widget/styles/themes';
import { withTheme } from '@emotion/react';

type Props = {
  disabled?: boolean;
  isLiveType?: boolean;
  isLoading: boolean;
  isPlaying: boolean;
  onClick: () => void;
  style?: Partial<Style>;
  theme: Theme;
};

function PlayButton({
  onClick,
  isPlaying,
  isLiveType = false,
  isLoading,
  style = {},
  theme,
  disabled,
}: Props) {
  const stopIcon =
    isLiveType ?
      <StopIcon height="12" x="24%" y="36%" />
    : <PauseIcon height="14.5" x="24%" y="33%" />;

  return (
    <LargeButton
      aria-label={isPlaying ? 'Pause' : 'Play'}
      data-test="play-button"
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      <LoadingSpinner
        loading={isLoading}
        strokeWidth={2}
        style={{
          background: disabled ? theme.fontSecondary! : theme.fontPrimary!,
          bufferColor: theme.backgroundPrimary!,
          spinnerFill: theme.fontPrimary!,
          spinnerStroke: theme.fontSecondary!,
          textColor: theme.backgroundPrimary!,
          ...style,
        }}
      >
        {isPlaying ? stopIcon : <PlayIcon height="12" x="29%" y="35%" />}
      </LoadingSpinner>
    </LargeButton>
  );
}

export default withTheme(PlayButton);
