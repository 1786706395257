import fonts from 'styles/fonts';
import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const ReadMore = styled('span')<Props>(({ theme }) => ({
  '&:before': {
    content: "'...'",
    fontWeight: 'normal',
  },
  color: theme.fontTertiary,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: fonts.size['16'],
  fontWeight: 'bold',
  [mediaQueryBuilder(mediaQueries.max.width['400'])]: {
    fontSize: fonts.size['14'],
  },
  [mediaQueryBuilder(mediaQueries.max.width['119'])]: {
    fontSize: fonts.size['12'],
  },
}));

export default ReadMore;
