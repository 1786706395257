import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const ButtonsWrapper = styled('div')({
  '* + *': {
    margin: '1rem',
  },
  marginBottom: '1rem',
  [mediaQueryBuilder(mediaQueries.max.height['169'])]: {
    marginBottom: '0',
  },
});

export default ButtonsWrapper;
