import Modal from 'widget/components/Modal';
import { ReactNode, useState } from 'react';

export default function useModal(
  Component: ReactNode,
): [ReactNode, () => void] {
  const [showModal, setModal] = useState(false);

  function toggleModal() {
    setModal(!showModal);
  }

  return [
    showModal ? <Modal onClose={toggleModal}>{Component}</Modal> : null,
    toggleModal,
  ];
}
