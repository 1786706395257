import { HttpErrorCode } from 'widget/Errors/types/HttpErrorCode';

export default class HttpError extends Error {
  httpErrorCode: HttpErrorCode | number;

  constructor(httpErrorCode: HttpErrorCode | number, httpErrorMessage: string) {
    super(httpErrorMessage);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError);
    }

    this.name = 'HttpError';
    this.httpErrorCode = httpErrorCode;
  }
}
