import Button from 'widget/components/Button';
import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import parseHEX from 'styles/helpers/colors/parseHEX';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Theme } from 'widget/styles/themes';

type Props = {
  'data-test'?: string;
};

const fadeIn = keyframes({
  opacity: 0,
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const ControlButton = styled(Button)<Props>(({ theme }: { theme: Theme }) => {
  const { r, g, b } = parseHEX(theme.fontPrimary!);

  return {
    '& svg': {
      fill: 'currentColor',
    },
    ':hover': {
      backgroundColor: `rgba(${r}, ${g}, ${b}, 0.1)`,
    },
    ':active': {
      backgroundColor: `rgba(${r}, ${g}, ${b}, 0.2)`,
    },
    animation: `${fadeIn} 0.5s ease`,
    color: theme.fontPrimary,
    display: 'inline-block',
    height: '4rem',
    lineHeight: 1,
    width: '4rem',
    borderRadius: '50%',
    [mediaQueryBuilder(mediaQueries.max.width['269'])]: {
      width: '3rem',
    },
  };
});

export default ControlButton;
