export default function PauseIcon(props: {
  [a: string]: string | number | boolean;
}) {
  return (
    <svg
      data-test="pause-icon"
      fill="currentColor"
      height="26"
      viewBox="0 0 22 26"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.94 25.353a3.54 3.54 0 0 0 3.53-3.53V4.177A3.54 3.54 0 0 0 3.94.647a3.54 3.54 0 0 0-3.529 3.53v17.646a3.54 3.54 0 0 0 3.53 3.53zM14.53 4.176v17.648a3.54 3.54 0 0 0 3.53 3.529 3.54 3.54 0 0 0 3.528-3.53V4.177A3.54 3.54 0 0 0 18.058.647a3.54 3.54 0 0 0-3.53 3.53z" />
    </svg>
  );
}
