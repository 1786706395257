import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import { Theme } from 'widget/styles/themes';

type Props = {
  'data-test'?: string;
  supportMobileView: boolean;
};

const Thumbnail = styled('img')<Props>(
  ({
    supportMobileView,
    theme,
  }: {
    supportMobileView: boolean;
    theme: Theme;
  }) => ({
    background: theme.backgroundSecondary,
    borderRadius: '0.6rem',
    boxShadow: theme.thumbBoxShadow,
    display: 'block',
    height: supportMobileView ? '16rem' : '8rem',
    left: supportMobileView ? '50%' : 'auto',
    margin: supportMobileView ? 0 : ' 0 0.5rem 0 0',
    objectFit: 'cover',
    position: supportMobileView ? 'fixed' : 'relative',
    right: 'auto',
    top: supportMobileView ? '50%' : '-0.5rem',
    transform: supportMobileView ? 'translate(-50%, -50%)' : 'inherit',
    width: supportMobileView ? '16rem' : '8rem',

    [mediaQueryBuilder(
      mediaQueries.max.height['399'],
      mediaQueries.max.width['400'],
    )]: {
      height: '7rem',
      left: 'auto',
      margin: '0rem 0.3rem 1rem 1rem',
      position: 'relative',
      top: '0.5rem',
      transform: 'inherit',
      width: '7rem',
    },
    [mediaQueryBuilder(
      mediaQueries.max.height['199'],
      mediaQueries.max.width['400'],
    )]: {
      height: '5.5rem',
      marginTop: '-6rem',
      width: '5.5rem',
    },
    [mediaQueryBuilder(
      mediaQueries.max.height['119'],
      mediaQueries.max.width['199'],
    )]: {
      height: '4.5rem',
      marginTop: 0,
      width: '4.5rem',
    },
    [mediaQueryBuilder(mediaQueries.max.height['99'])]: {
      marginBottom: '0rem',
      top: '0.35rem',
    },
    [mediaQueryBuilder(
      mediaQueries.max.height['59'],
      mediaQueries.max.width['119'],
    )]: {
      display: 'none',
    },
  }),
);

export default Thumbnail;
