import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import { Theme } from 'widget/styles/themes';

type Props = {
  'data-test'?: string;
};

const Toolbar = styled('div')<Props>(({ theme }: { theme: Theme }) => ({
  alignItems: 'flex-end',
  background: theme.backgroundSecondary,
  boxSizing: 'border-box',
  display: 'flex',
  height: '4.5rem',
  justifyContent: 'space-between',
  padding: '0.255rem',
  [mediaQueryBuilder(
    mediaQueries.max.height['399'],
    mediaQueries.max.width['199'],
  )]: {
    height: '3.8rem',
  },
  [mediaQueryBuilder(mediaQueries.max.height['119'])]: {
    background: 'transparent',
  },
  [mediaQueryBuilder(mediaQueries.max.height['99'])]: {
    padding: '0 0.255rem',
  },
}));

export default Toolbar;
