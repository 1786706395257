import fonts from 'styles/fonts';
import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
  hasPlayed?: boolean;
  isPodcast?: boolean;
};

const Heading = styled('h1')<Props>(({ hasPlayed, isPodcast }: Props) => {
  const marginRightTruncated = isPodcast ? '22rem' : '14rem';

  return {
    a: {
      display: 'inline', // required for ellipsis to work
    },
    fontSize: fonts.size['16'],
    fontWeight: 'bold',
    lineHeight: fonts.lineHeight['20'],
    overflow: 'hidden',
    paddingBottom: '0.3rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [mediaQueryBuilder([
      mediaQueries.max.height['99'],
      mediaQueries.min.width['420'],
    ])]: {
      marginRight: hasPlayed ? marginRightTruncated : '0',
    },
  };
});

export default Heading;
