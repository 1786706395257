import Button from 'widget/components/Button';
import fonts from 'styles/fonts';
import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import { Theme } from 'widget/styles/themes';

type Props = {
  'data-test'?: string;
};

const RoundedButton = styled(Button)<Props>(({ theme }: { theme: Theme }) => ({
  '& span': {
    display: 'none',
  },
  '& svg': {
    display: 'inline-block',
    fill: 'currentColor',
    height: '1.8rem',
    position: 'relative',
    top: '0.1rem',
    width: '1.8rem',
  },
  background: 'transparent',
  border: `0.1rem solid ${theme.fontPrimary}`,
  borderRadius: '1.7rem',
  boxSizing: 'border-box',
  color: theme.fontPrimary,
  fontSize: fonts.size['14'],
  height: '3.2rem',
  lineHeight: fonts.lineHeight['14'],
  padding: 0,
  textAlign: 'center',
  width: '3.2rem',
  [mediaQueryBuilder(mediaQueries.min.width['420'])]: {
    '& span': {
      display: 'inline-block',
      marginLeft: '0.3rem',
      position: 'relative',
      top: '-0.3rem',
    },
    '& svg': {
      width: '1.5rem',
    },
    padding: '.5rem 1.4rem',
    width: 'auto',
  },
}));

export default RoundedButton;
