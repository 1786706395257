import Body from 'widget/components/InfoCard/primitives/Body';
import ContentContainer from 'widget/components/ContentContainer';
import Description from 'widget/components/Description';
import Header from 'widget/components/Header';
import Heading from 'widget/components/Heading';
import HeadingContainer from 'widget/components/HeadingContainer';
import InfoHeading from 'widget/components/InfoHeading';
import InfoHeadingSmallHeight from 'widget/components/InfoHeadingSmallHeight';
import ReadMore from 'widget/components/MainContent/primitives/ReadMore';
import SubHeading from 'widget/components/SubHeading';
import transfromLinks from 'widget/utils/helpers/transformLinks';
import Truncate from 'react-truncate';
import { useEffect, useRef } from 'react';
import type { ReactNode } from 'react';

type Props = {
  description?: string;
  hasPlayed?: boolean;
  icons: Array<ReactNode>;
  infoHeading: string;
  infoHeadingSmallHeight?: string;
  isPodcast?: boolean;
  showDescription?: boolean;
  subHeading?: ReactNode;
  title?: ReactNode;
  toggleInfoModal?: () => void;
};

export default function MainContent({
  description,
  hasPlayed,
  icons,
  infoHeading,
  infoHeadingSmallHeight,
  isPodcast,
  showDescription,
  subHeading,
  title,
  toggleInfoModal,
}: Props) {
  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (descriptionRef.current) {
      transfromLinks(descriptionRef.current);
    }
  }, [description]);

  return (
    <Header>
      <ContentContainer>
        {icons}
        <HeadingContainer>
          <InfoHeading
            data-test="widget-info-heading"
            hasPlayed={hasPlayed}
            isPodcast={isPodcast}
          >
            {infoHeadingSmallHeight && (
              <InfoHeadingSmallHeight>
                {infoHeadingSmallHeight}
              </InfoHeadingSmallHeight>
            )}
            {infoHeading}
          </InfoHeading>
          <Heading
            data-test="widget-heading"
            hasPlayed={hasPlayed}
            isPodcast={isPodcast}
          >
            {title}
          </Heading>
          <SubHeading data-test="widget-sub-heading">{subHeading}</SubHeading>
        </HeadingContainer>

        {description && showDescription && (
          <Description>
            <Truncate
              ellipsis={
                <ReadMore onClick={toggleInfoModal}>Read more</ReadMore>
              }
              lines={2}
              trimWhitespace
            >
              <Body
                dangerouslySetInnerHTML={{ __html: description }}
                ref={descriptionRef}
              />
            </Truncate>
          </Description>
        )}
      </ContentContainer>
    </Header>
  );
}
