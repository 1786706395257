import RoundedButton from 'widget/components/RoundedButton';
import styled from '@emotion/styled';
import { Theme } from 'widget/styles/themes';

type Props = {
  'data-test'?: string;
};

const ReloadButton = styled(RoundedButton)<Props>(
  ({ theme }: { theme: Theme }) => ({
    background: theme.fontPrimary,
    borderColor: theme.backgroundPrimary,
    color: theme.backgroundPrimary,
    minWidth: '11rem',
  }),
);

export default ReloadButton;
