import logger from 'modules/Logger';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import player from 'widget/player';
import PlayerState, {
  PlayerErrorState,
  PlayerWarningState,
} from 'modules/Player/constants/playerState';
import useMount from 'hooks/useMount';
import { ComponentType, useEffect, useRef, useState } from 'react';
import {
  context,
  defaultMethod,
  defaultMethodTypes,
  EVENT_METHOD_CB,
  nullEvent,
  playerStateMap,
  src,
  version,
} from 'widget/components/withPlayerJS/constants';
import { CONTEXTS } from 'widget/logger';
import {
  createEmitMessageFnc,
  createHandleErrorMsgFnc,
  getInitialEventListeners,
  getSupportedEventsAndMethods,
} from 'widget/components/withPlayerJS/helpers';
import {
  Events,
  EventType,
  Listeners,
  MetaForPlayerJS,
  MethodType,
  PlayerJSData,
  PlayerJSRefs,
  Props,
} from 'widget/components/withPlayerJS/types';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { PlayerProgress } from 'widget/hooks/usePlayerProgress';
import { throttle } from 'lodash-es';
import { usePlayerProgress, usePlayerState, useTranslate } from 'widget/hooks';

export default function withPlayerJS<PropsType>(
  Player: ComponentType<PropsType & Props>,
): ComponentType<any> {
  return function WrappedPlayer(props) {
    const { dependentWindow, metadata } = props;

    let windowRef: (Window & { play?: any }) | undefined;
    if (__CLIENT__) windowRef = dependentWindow || window; // for tests

    const [playerJSactive, setPlayerJSactive] = useState(false);

    const [playerState] = usePlayerState();
    const [progress] = usePlayerProgress();
    const [EVENT, setEvents] = useState({} as EventType);
    const [METHOD, setMethods] = useState({} as MethodType);
    const [methodPayload, setMethodPayload] = useState({});
    const [eventListeners, setEventListeners] = useState({} as Listeners);

    const errorState = {
      ...PlayerErrorState,
      ...PlayerWarningState,
    };
    type ErrorState = PlayerErrorState | PlayerWarningState;

    const refs: PlayerJSRefs = {
      methodTypes: useRef(defaultMethodTypes),
      mute: useRef(defaultMethod),
      pause: useRef(defaultMethod),
      play: useRef(defaultMethod),
      setCurrentTime: useRef(defaultMethod),
      setLoop: useRef(defaultMethod),
      setVolume: useRef(defaultMethod),
      unmute: useRef(defaultMethod),
    };

    const handleErrorMsg = createHandleErrorMsgFnc(
      useTranslate() as IGetTranslateFunctionResponse,
    );
    const emitMessage = createEmitMessageFnc(context, version, windowRef);

    const emitCustomError = (msg: string) =>
      emitMessage(EVENT.ERROR, handleErrorMsg({ code: -1, msg }));

    // any partner request for a nonexistent method or for a method not supported by
    // a particular Player (on demand methods, scrubbing)
    const emitMethodNotSupported = (method: string) =>
      emitMessage(EVENT.ERROR, handleErrorMsg({ code: 2, method }));

    function handleEventListener(data: PlayerJSData) {
      // value = event name
      const { listener, method, value } = data;
      // check if we are set up to listen for that event
      let listeners = eventListeners[value as Events];
      if (listeners) {
        if (method === METHOD.ADD_EVENT_LISTENER) {
          listeners = [...listeners, listener];
        } else {
          // no listener name specified ? remove all listeners for this event
          listeners =
            listener ? listeners.filter(name => name !== listener) : [];
        }
        setEventListeners({
          ...eventListeners,
          [value as Events]: listeners,
        });
      } else {
        emitCustomError(`${value} listener not supported`);
      }
    }

    // only Podcast should allow scrubbing
    async function invokeRestrictedMethods(data: PlayerJSData) {
      const { method, value } = data;
      if (method) {
        if (method === METHOD.SET_CURRENT_TIME) {
          const processedVal = parseInt(value as string, 10);
          if (!Number.isNaN(processedVal))
            refs.setCurrentTime.current(nullEvent, processedVal);
          else emitCustomError('setCurrentTime requires a value in seconds');
        } else {
          emitMethodNotSupported(method);
        }
      }
    }

    // only non-livestream Players should have these methods
    async function invokeOnDemandMethods(data: PlayerJSData) {
      const { listener, method, value } = data;
      if (method) {
        switch (method) {
          case METHOD.GET_DURATION:
            if (progress.duration)
              emitMessage(method, progress.duration, listener);
            else
              handleEventListener({
                listener,
                method: METHOD.ADD_EVENT_LISTENER,
                value: method,
              });
            break;
          case METHOD.GET_CURRENT_TIME:
            emitMessage(method, await player.getPosition(), listener);
            break;
          case METHOD.SET_LOOP:
            if (
              typeof value === 'boolean' ||
              value === 'true' ||
              value === 'false'
            ) {
              refs.setLoop.current(nullEvent, value && value !== 'false');
            } else emitCustomError('setLoop requires a value of true or false');
            break;
          case METHOD.GET_LOOP:
            emitMessage(method, await player.getLoop(), listener);
            break;
          default:
            if (refs.methodTypes.current.isUnrestricted)
              invokeRestrictedMethods(data);
            else emitMethodNotSupported(method);
        }
      }
    }

    // methods shared between all Players
    async function invokeMethods(data: PlayerJSData) {
      const { listener, method, value } = data;
      let processedVal;
      if (method) {
        switch (method) {
          case METHOD.REMOVE_EVENT_LISTENER:
          case METHOD.ADD_EVENT_LISTENER:
            handleEventListener(data);
            break;
          case METHOD.PLAY:
            if (playerState !== PlayerState.Playing)
              refs.play.current(nullEvent, PLAYED_FROM.RESP_WIDGET_PLAYER_JS);
            else emitCustomError('already playing');
            break;
          case METHOD.PAUSE:
            if (playerState === PlayerState.Playing)
              refs.pause.current(nullEvent, PLAYED_FROM.RESP_WIDGET_PLAYER_JS);
            else emitCustomError('already paused');
            break;
          case METHOD.GET_PAUSED:
            emitMessage(method, playerState === PlayerState.Paused, listener);
            break;
          case METHOD.MUTE:
          case METHOD.UNMUTE:
            refs[method].current();
            break;
          case METHOD.GET_MUTED:
            emitMessage(method, await player.getIsMuted(), listener);
            break;
          case METHOD.SET_VOLUME:
            processedVal = parseInt(value as string, 10);
            if (!Number.isNaN(processedVal))
              refs.setVolume.current(nullEvent, processedVal);
            else emitCustomError('setVolume requires a value between 0-100');
            break;
          case METHOD.GET_VOLUME:
            emitMessage(method, await player.getVolume(), listener);
            break;
          default:
            if (!refs.methodTypes.current.isLiveStream)
              invokeOnDemandMethods(data);
            else emitMethodNotSupported(method);
        }
      }
    }

    const throttledSendTimeUpdate = useRef(
      throttle(
        (
          { duration, position }: PlayerProgress,
          listeners: Listeners,
          event: Events,
        ) =>
          listeners[event]?.forEach(listener =>
            emitMessage(event, { duration, seconds: position }, listener),
          ),
        1000,
      ),
    );

    useEffect(() => {
      const event = EVENT.TIME_UPDATE;
      // check for length so throttle doesn't make tests fail
      if (eventListeners[event]?.length)
        throttledSendTimeUpdate.current(progress, eventListeners, event);
    }, [progress.position]);

    useEffect(() => {
      // handle async getDuration
      const event = EVENT.GET_DURATION;
      if (progress.duration) {
        // eslint-disable-next-line no-unused-expressions
        eventListeners[event]?.forEach(listener => {
          emitMessage(event, progress.duration, listener);
          handleEventListener({
            listener,
            method: METHOD.REMOVE_EVENT_LISTENER,
            value: event,
          });
        });
      }
    }, [progress.duration]);

    useEffect(() => {
      // handle requested event listeners tied to player state
      if (playerState !== PlayerState.Idle) {
        let event = playerStateMap[playerState];
        let value: { code: number; msg: string };
        if (
          !event ||
          Object.values(errorState).includes(playerState as ErrorState)
        ) {
          event = EVENT.ERROR;
          value = handleErrorMsg({
            code: -1,
            error: playerState as ErrorState,
          });
        }
        eventListeners[event]?.forEach(listener =>
          emitMessage(event as Events, value, listener),
        );
      }
    }, [playerState]);

    // handle custom (non-PlayerJS) metadata events
    // throttled because Artist/Player sometimes cycle thru tracks before landing
    const throttledSendMetaData = useRef(
      throttle(
        (meta: MetaForPlayerJS, listeners: Listeners, event: Events) => {
          // if a key is defined as undefined, preserve it with empty string
          const metaPreserveUndefinedKeys = Object.keys(meta).reduce(
            (preservedMeta, key) => ({
              ...preservedMeta,
              [key]: meta[key as keyof MetaForPlayerJS] || '',
            }),
            {} as MetaForPlayerJS,
          );
          listeners[event]?.forEach(listener =>
            emitMessage(event, metaPreserveUndefinedKeys, listener),
          );
        },
        500,
        { leading: false },
      ),
    );

    const prevMeta = useRef({} as MetaForPlayerJS);

    useEffect(() => {
      const { isLiveStream } = refs.methodTypes.current;
      const newTrack =
        metadata.episode !== prevMeta.current.episode ||
        metadata.title !== prevMeta.current.title;
      const newImage = metadata.trackImage !== prevMeta.current.trackImage;
      // live meta comes piecemeal, so only live needs to check for new image AND track
      if (newTrack && (newImage || !isLiveStream)) {
        throttledSendMetaData.current(metadata, eventListeners, EVENT.METADATA);
        prevMeta.current = metadata;
      }
    }, [metadata]);

    useEffect(() => {
      throttledSendMetaData.current(metadata, eventListeners, EVENT.METADATA);
    }, [eventListeners.metadata?.length]);

    useEffect(() => {
      // to avoid autoplay errors:
      // if parent frame is communicating with widget via playerjs
      // put play() method on the window object for direct access
      // NOTE: this only works if widget & parent have the same origin
      if (playerJSactive && windowRef) {
        windowRef.play = () => invokeMethods({ method: METHOD.PLAY });
      }
    }, [playerJSactive]);

    // STEP 3 of 3: requested methods are invoked via hook
    useEffect(() => {
      invokeMethods(methodPayload);
    }, [methodPayload]);

    // STEP 2 of 3: set state to trigger methods outside
    // of stale closure in useMount() listener
    function parseMessage(event: { data: string }) {
      if (typeof event.data === 'string') {
        let data: { [key: string]: string } = {};
        try {
          data = JSON.parse(event.data);
          if (data.context === context) {
            if (data.method) setMethodPayload(data as PlayerJSData);
            if (!playerJSactive) setPlayerJSactive(true);
          }
        } catch (e) {
          const errObj = new Error(
            `withPlayerJS expects stringified JSON in event.data: ${e}`,
          );
          logger.error(CONTEXTS.REACT, errObj.message, {}, errObj);
        }
      }
    }

    // STEP 1 of 3: receive and parse message via window listener
    useMount(() => {
      windowRef?.addEventListener('message', parseMessage);

      const [supportedEvents, methods] = getSupportedEventsAndMethods(
        refs.methodTypes.current,
      );
      const allEvents = { ...supportedEvents, ...EVENT_METHOD_CB };
      setEvents(allEvents as EventType);
      setMethods(methods as MethodType);
      setEventListeners(getInitialEventListeners(allEvents as EventType));

      // NB: parent will not fire messages unless it receives "ready" from widget
      const value = {
        events: Object.values(supportedEvents),
        methods: Object.values(methods),
        src,
      };
      emitMessage(allEvents.READY, { value });

      return () => windowRef?.removeEventListener('message', parseMessage);
    });

    return <Player {...props} refs={refs} />;
  };
}
