export default function ShareIcon() {
  return (
    <svg
      fill="none"
      height="23"
      viewBox="0 0 16 23"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.335.429a.5.5 0 0 0-.753.004l-3.33 3.856a1.033 1.033 0 0 0 .11 1.46c.434.373 1.09.324 1.464-.109L7 4.281v9.867a1 1 0 0 0 2 0V4.313l1.181 1.336c.38.43 1.036.47 1.467.092.43-.378.472-1.032.093-1.46L8.335.428z" />
      <path d="M0 9.069c0-1.032.84-1.853 1.86-1.853H4a1 1 0 0 1 0 2H2V21h12V9.216h-2a1 1 0 0 1 0-2h2.14c1.02 0 1.86.822 1.86 1.853v12.079c0 1.03-.84 1.852-1.86 1.852H1.86C.841 23 0 22.18 0 21.148V9.068z" />
    </svg>
  );
}
