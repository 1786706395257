import fonts from 'styles/fonts';
import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Description = styled('div')({
  '*': {
    fontSize: fonts.size['16'],
    lineHeight: fonts.size['20'],
    [mediaQueryBuilder(mediaQueries.max.width['400'])]: {
      fontSize: fonts.size['14'],
      lineHeight: fonts.size['18'],
    },
    [mediaQueryBuilder(mediaQueries.max.width['119'])]: {
      fontSize: fonts.size['12'],
      lineHeight: fonts.size['16'],
    },
  },
  display: 'none',
  height: '4rem',
  overflow: 'hidden',
  width: '100%',

  [mediaQueryBuilder('(min-height: 240px)')]: {
    display: 'block',
  },

  [mediaQueryBuilder(mediaQueries.min.height['301'])]: {
    display: 'none',
  },
});

export default Description;
