import addFit from 'widget/utils/helpers/addFit';
import ButtonsWrapper from 'widget/Errors/PlaybackError/primitives/ButtonsWrapper';
import ErrorBody from 'widget/Errors/PlaybackError/primitives/ErrorBody';
import ErrorContainer from 'widget/Errors/ErrorView/primitives/ErrorContainer';
import ErrorDescription from 'widget/Errors/PlaybackError/primitives/ErrorDescription';
import ErrorIHeartLink from 'widget/Errors/ErrorView/primitives/ErrorIHeartLink';
import ErrorTextLink from 'widget/Errors/ErrorView/primitives/ErrorTextLink';
import IHeartLink from 'widget/Errors/PlaybackError/primitives/IHeartLink';
import MessageContainer from 'widget/Errors/PlaybackError/primitives/MessageContainer';
import ReloadButton from './primitives/ReloadButton';
import ShouldShow from 'components/ShouldShow';
import Thumb from 'widget/Errors/PlaybackError/primitives/Thumb';
import { getErrorMessageTranslator } from './helpers/getErrorMessageTranslator';
import { PlayerErrorState } from 'modules/Player/constants/playerState';
import { useTranslate } from 'widget/hooks';

export default function PlaybackError({
  error,
  getTheAppLink,
  imageUrl,
}: {
  error?: PlayerErrorState;
  getTheAppLink?: string;
  imageUrl?: string;
}) {
  const translate = useTranslate();
  const getErrorMessage = getErrorMessageTranslator(translate);
  const errorDescription = getErrorMessage(error);
  const accessDenied = error === PlayerErrorState.AccessDenied;
  const invalidMedia = error === PlayerErrorState.InvalidMedia;
  const unsupportedCountry = error === PlayerErrorState.UnsupportedCountry;
  return (
    <ErrorContainer>
      <ErrorIHeartLink data-test="error-iheart-link" />
      <MessageContainer>
        <div>
          {imageUrl && (
            <Thumb data-test="image-thumbnail" src={addFit(imageUrl)} />
          )}
          <ErrorDescription data-test="error-text">
            {errorDescription}
          </ErrorDescription>
          <ShouldShow shouldShow={accessDenied}>
            <ErrorBody>
              Please pick a different station or listen to this station using
              our free iHeart app.
            </ErrorBody>
          </ShouldShow>
          <ButtonsWrapper>
            <ShouldShow shouldShow={!accessDenied && !unsupportedCountry}>
              <ReloadButton onClick={() => window.location.reload()}>
                {translate('Retry')}
              </ReloadButton>
              <IHeartLink
                data-test="iheart-link"
                href="https://www.iheart.com"
                target="_blank"
              >
                iHeart.com
              </IHeartLink>
            </ShouldShow>
            <ShouldShow shouldShow={accessDenied}>
              <IHeartLink href={getTheAppLink} target="_blank">
                {translate('Listen in App')}
              </IHeartLink>

              <ReloadButton onClick={() => window.location.reload()}>
                {translate('Got it')}
              </ReloadButton>
            </ShouldShow>
          </ButtonsWrapper>
          <ErrorTextLink
            href={
              invalidMedia ?
                'https://help.iheart.com'
              : 'https://help.iheart.com/hc/articles/229182368-Troubleshooting-browser-issues-with-iHeartRadio'
            }
            target="_blank"
          >
            {invalidMedia ?
              translate('Report this issue')
            : translate('View Troubleshooting')}
          </ErrorTextLink>
        </div>
      </MessageContainer>
    </ErrorContainer>
  );
}
