import fonts from 'styles/fonts';
import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import { Theme } from 'widget/styles/themes';

type Props = {
  'data-test'?: string;
  theme?: Theme;
};

const SubHeading = styled('h2')<Props>(({ theme }) => ({
  a: {
    color: theme.fontSecondary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  fontSize: fonts.size['14'],
  fontWeight: 'normal',
  lineHeight: fonts.lineHeight['16'],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  [mediaQueryBuilder(mediaQueries.max.height['149'])]: {
    display: 'none',
  },
}));

export default SubHeading;
