import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const HeadingContainer = styled('div')<Props>({
  [mediaQueryBuilder(mediaQueries.max.height['99'])]: {
    left: '5rem',
    position: 'absolute',
    width: 'calc(100% - 11rem)',
  },
  [mediaQueryBuilder(mediaQueries.max.height['59'])]: {
    width: 'calc(100% - 3rem)',
  },
  [mediaQueryBuilder(mediaQueries.max.width['149'])]: {
    display: 'none',
  },
});

export default HeadingContainer;
