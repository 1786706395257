import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const LeftButtonGroup = styled('div')<Props>({
  alignItems: 'flex-end',
  display: 'flex',
  width: '100%',
});

export default LeftButtonGroup;
